import axios from "axios";

export default {
    getAboutAuthority({ commit, state }) {
        if (!Object.keys(state.aboutAuthority).length)
            axios
                .get(process.env.VUE_APP_APIEndPoint + 'AboutAuthority/GetAboutAuthorityDetailsById/AboutAuthority/')
                .then(response => {
                    commit("GET_ABOUT_AUTHORITY", {
                        data: response.data.Data,
                    })
                });
    },
    getBoardOfDirectors({ commit, state }) {
        if (!Object.keys(state.boardOfDirectors).length)
            axios
                .post(process.env.VUE_APP_APIEndPoint + 'BoardDirector/PublicGetAll', {
                    "pageNumber": 0,
                    "pageSize": 0,
                })
                .then(response => {
                    commit("GET_BOARD_OF_DIRECTORS", {
                        data: response.data.Data,
                    })
                })
    },
    getOrganizationalChart({ commit, state }) {
        if (!Object.keys(state.organizationalChart).length)
            axios
                .get(process.env.VUE_APP_APIEndPoint + 'AboutAuthority/GetAboutAuthorityDetailsById/OrganizationalChart')
                .then(response => {
                    commit("GET_ORGANIZATIONAL_CHART", {
                        data: response.data.Data,
                    })
                })
    },
    getGovernorOfAuthority({ commit, state }) {
        if (!Object.keys(state.governorOfAuthority).length)
            axios
                .get(process.env.VUE_APP_APIEndPoint + 'AboutAuthority/GetAboutAuthorityDetailsById/GovernorBiography')
                .then(response => {
                    commit("GET_GOVERNOR_OF_AUTHORITY", {
                        data: response.data.Data,
                    })
                })
    }
}