<template>
    <div>
        <Header></Header>
        <slot></slot>
        <Footer v-if="$route.name != 'Home'"></Footer>
    </div>
</template>

<script>
    import Header from '@/components/shared/Header.vue';
    import Footer from '@/components/shared/Footer.vue';

    export default {
        name: "master_layout",
        components: {
            Header,
            Footer
        }
    };
</script>