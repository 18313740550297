//eslint-disable-next-line
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)



const router = new Router({
    linkActiveClass: "active",
    scrollBehavior(to, from, savedPosition) // eslint-disable-line no-unused-vars
    {
        return { x: 0, y: 0, behavior: 'smooth' }
    },

    routes: [
        {
            path: '/',
            name: 'Home',
            component: () => import('@/views/Home.vue'),
        },
        {
            path: '/services',
            name: 'Services',
            component: () => import('@/views/Services/Services.vue'),
            meta: {
                title: {
                    en: 'Service Details',
                    ar: 'الخدمات الإلكترونية'
                }
            }
        },
        {
            path: '/service/:id',
            name: 'ServiceDetails',
            component: () => import('@/views/Services/ServiceDetails.vue'),
            meta: {
                title: {
                    en: 'Service Details',
                    ar: 'الخدمات الإلكترونية'
                }
            }
        },
        {
            path: '/contact-us',
            name: 'ContactUs',
            component: () => import('@/views/ContactUs.vue'),
            meta: {
                title: {
                    en: 'Contact Us',
                    ar: 'اتصل بنا'
                }
            }
        },
        {
            path: '/our-branches',
            name: 'OurBranches',
            component: () => import('@/views/OurBranches.vue'),
            meta: {
                title: {
                    en: 'Our Branches',
                    ar: 'فروعنا'
                }

            }
        },
        {
            path: '/site-map',
            name: 'SiteMap',
            component: () => import('@/views/SiteMap.vue'),
            meta: {
                title: {
                    en: 'Sitemap',
                    ar: 'خارطة الموقع'
                }

            }
        },

        {
            path: '/annual-report',
            name: 'Reports',
            component: () => import('@/views/ReportsList.vue'),
            meta: {
                title: {
                    en: 'Annual report',
                    ar: 'التقرير السنوي'
                }

            }
        },

        {
            path: '/Statistics',
            name: 'Statistics',
            component: () => import('@/views/StatisticsList.vue'),
        },

        {
            path: '/faq',
            name: 'Faq',
            component: () => import('@/views/FAQs/FaqsList.vue'),
            meta: {
                title: {
                    en: 'FAQ',
                    ar: 'الأسئلة الشائعة'
                }

            }
        },

        // Start Media Center
        {
            path: '/News',
            name: 'News',
            component: () => import('@/views/News/NewsList.vue'),
            meta: {
                title: {
                    en: 'News',
                    ar: 'الأخبار'
                }
            }
        },
        {
            path: '/News/:id',
            name: 'NewsDetails',
            component: () => import('@/views/News/NewsDetails.vue'),
            meta: {
                title: {
                    en: 'News',
                    ar: 'الأخبار'
                }

            }
        },
        {
            path: '/Events',
            name: 'Events',
            component: () => import('@/views/Events/EventsList.vue'),
        },
        {
            path: '/Events/:id',
            name: 'EventsDetails',
            component: () => import('@/views/Events/EventsDetails.vue'),
        },
        {
            path: '/Programs/:id',
            name: 'ProgramDetails',
            component: () => import('@/views/Programs/ProgramDetails.vue'),
        },
        {
            path: '/Result/:id/:moduleType',
            name: 'HashTagesResult',
            component: () => import('@/views/HashTages/HashTagesList.vue'),
        },
        {
            path: '/photo-gallery',
            name: 'PhotoGallery',
            component: () => import('@/views/PhotoGallery/PhotoGalleryList.vue'),
            meta: {
                title: {
                    en: 'Photo Gallery',
                    ar: 'مجلدات الصور'
                }

            }
        },
        {
            path: '/photo-gallery/:id',
            name: 'PhotoGalleryDetails',
            component: () => import('@/views/PhotoGallery/PhotoGalleryDetails.vue'),
            meta: {
                title: {
                    en: 'Photo Gallery',
                    ar: 'مجلدات الصور'
                }

            }
        },
        {
            path: '/video-gallery',
            name: 'VideoGallery',
            component: () => import('@/views/VideoGallery/VideoGalleryList.vue'),
            meta: {
                title: {
                    en: 'Video Gallery',
                    ar: 'مجلدات الفيديوهات والانفوجرافيك'
                }

            }
        },
        {
            path: '/video-gallery/:id',
            name: 'VideoGalleryDetails',
            component: () => import('@/views/VideoGallery/VideoGalleryDetails.vue'),
            meta: {
                title: {
                    en: 'Video Gallery',
                    ar: 'مجلدات الفيديوهات والانفوجرافيك'
                }

            }
        },
        {
            path: '/media',
            name: 'Media',
            component: () => import('@/views/MediaCenter.vue'),
            meta: {
                title: {
                    en: 'Media',
                    ar: 'الوسائط'
                }
            }
        },
        //{
        //    path: '/Questionnaires',
        //    name: 'Questionnaires',
        //    component: () => import('@/views/Questionnaires/QuestionnairesList.vue'),
        //},
        //{
        //    path: '/Questionnaires/:id',
        //    name: 'QuestionnairesDetails',
        //    component: () => import('@/views/Questionnaires/QuestionnairesDetails.vue'),
        //},
        // End Media Center
        /// opended data 


        // search results
        {
            path: '/SearchResults',
            name: 'SearchResults',
            component: () => import('@/views/SearchResults.vue'),
            meta: {
                title: {
                    en: 'Search',
                    ar: 'بحث'
                }

            }
        },
        // Start About Us
        {
            path: '/brief-about-authority',
            name: 'AboutUs',
            component: () => import('@/views/AboutUs/AboutUs.vue'),
            meta: {
                title: {
                    en: 'About Authority',
                    ar: 'نبذة عن الهيئة'
                }

            }
        },
        {
            path: '/organizational-chart',
            name: 'OrganizationStructure',
            component: () => import('@/views/AboutUs/OrganizationStructure.vue'),
            meta: {
                title: {
                    en: 'Organizational Chart',
                    ar: 'الهيكل التنظيمي'
                }

            }
        },
        {
            path: '/governor-of-authority',
            name: 'GovernorOfAuthority',
            component: () => import('@/views/AboutUs/GovernorOfAuthority.vue'),
            meta: {
                title: {
                    en: "Governor's biography",
                    ar: 'محافظ الهيئة'
                }
            }
        },
        {
            path: '/board-of-directors',
            name: 'BoardOfDirectors',
            component: () => import('@/views/AboutUs/BoardOfDirectors.vue'),
            meta: {
                title: {
                    en: 'Boards Of Directors',
                    ar: 'مجلس الإدارة'
                }
            }
        },
        // End About Us

        // Start Partner Services
        {
            path: '/regulations-and-policies',
            name: 'RegulationsAndPolicies',
            component: () => import('@/views/PartnerServices/RegulationsAndPolicies.vue'),
            meta: {
                title: {
                    en: 'Governmental Needs',
                    ar: 'الأنظمة واللوائح'
                }

            }
        },
        {
            path: '/governmental-needs',
            name: 'GovernmentalNeeds',
            component: () => import('@/views/PartnerServices/GovernmentalNeeds.vue'),
            meta: {
                title: {
                    en: 'Governmental Needs',
                    ar: 'الاحتياج الحكومي'
                }

            }
        },
        {
            path: '/real-estate-affairs',
            name: 'RealEstateAffairs',
            component: () => import('@/views/PartnerServices/RealEstateAffairs.vue'),
            meta: {
                title: {
                    en: 'Real Estate Affairs',
                    ar: 'شؤون العقارات'
                }

            }
        },
        {
            path: '/investment-and-business-development',
            name: 'InvestmentAndBusinessDevelopment',
            component: () => import('@/views/PartnerServices/InvestmentAndBusinessDevelopment.vue'),
            meta: {
                title: {
                    en: 'Investment And Business Development',
                    ar: 'الاستثمار وتطوير الأعمال'
                }

            }
        },
        {
            path: '/tenders',
            name: 'Tenders',
            component: () => import('@/views/PartnerServices/Tenders.vue'),
            meta: {
                title: {
                    en: 'Tenders',
                    ar: 'المنافسات'
                }

            }
        },
        {
            path: '/investment-opportunity',
            name: 'InvestmentOpportunity',
            component: () => import('@/views/PartnerServices/InvestmentOpportunity.vue'),
            meta: {
                title: {
                    en: 'Investment Opportunities',
                    ar: 'الفرص الاستثمارية'
                }
            }
        },
        {
            path: '/investment-opportunity/:id',
            name: 'InvestmentOpportunityDetails',
            component: () => import('@/views/PartnerServices/InvestmentOpportunityDetails.vue'),
            meta: {
                title: {
                    en: 'Investment Opportunities',
                    ar: 'الفرص الاستثمارية'
                }
            }
        },
        {
            path: '/tenders-list',
            name: 'TendersList',
            component: () => import('@/views/PartnerServices/TendersList.vue'),
            meta: {
                title: {
                    en: 'Tenders',
                    ar: 'إعلانات المنافسات'
                }
            }
        },
        {
            path: '/tender/:id',
            name: 'TenderDetails',
            component: () => import('@/views/PartnerServices/TenderDetails.vue'),
            meta: {
                title: {
                    en: 'Tenders',
                    ar: 'إعلانات المنافسات'
                }
            }
        },
        // End Partner Services


        {
            path: '/InternationalCooperation',
            name: 'InternationalCooperationList',
            component: () => import('@/views/InternationalCooperation/InternationalCooperationList.vue'),
        },
        {
            path: '/InternationalCooperation/:id',
            name: 'InternationalCooperationDetails',
            component: () => import('@/views/InternationalCooperation/InternationalCooperationDetails.vue'),
        },
        {
            path: '/privacy',
            name: 'Privacy',
            component: () => import('@/views/FooterPages/Privacy.vue'),
            meta: {
                title: {
                    en: 'privacy',
                    ar: 'سياسة الاستخدام الامن والخصوصية'
                }
            }
        },
        {
            path: '/UserTerms',
            name: 'UserTerms',
            component: () => import('@/views/FooterPages/UserTerms.vue'),
        },
        {
            path: '/spga-identity',
            name: 'AuthorityIdentity',
            component: () => import('@/views/AuthorityIdentity.vue'),
            meta: {
                title: {
                    en: 'Authority Identity',
                    ar: 'هوية الهيئة'
                }
            }
        },
        {
            path: '/our-branches',
            name: 'Branches',
            component: () => import('@/views/Branches.vue'),
        },
        {
            path: '/greeting-card',
            name: 'GreetingCard',
            component: () => import('@/views/GreetingCard.vue'),
            meta: {
                title: {
                    en: 'Greeting cards',
                    ar: 'بطاقات التهنئة'
                }
            }
        },
        {
            path: '/open-data',
            name: 'OpenData',
            component: () => import('@/views/OpenData.vue'),
            meta: {
                title: {
                    en: 'Open Data',
                    ar: 'البيانات المفتوحة'
                }

            }
        },
        {
            path: '/open-data-files',
            name: 'OpenedDataRequest',
            component: () => import('@/views/OpenedDataRequest.vue'),
            meta: {
                title: {
                    en: 'Open Data',
                    ar: 'البيانات المفتوحة'
                }

            }
        },
        {
            path: '/open-data-initiate-request',
            name: 'OpenDataInitiateRequest',
            component: () => import('@/views/OpenDataInitiateRequest.vue'),
            meta: {
                title: {
                    en: 'Open Data Request',
                    ar: 'طلب بيانات مفتوحة'
                }

            }
        },
        {
            path: '/social-responsibility',
            name: 'SocialResponsibility',
            component: () => import('@/views/SocialResponsibility.vue'),
            meta: {
                title: {
                    en: 'Social Responsibility',
                    ar: 'المسؤولية المجتمعية'
                }

            }
        },


        {
            path: '/relevant-authorities',
            name: 'RelevantAuthorities',
            component: () => import('@/views/RelevantAuthorities.vue'),
            meta: {
                title: {
                    en: 'Relevant Authorities',
                    ar: 'الجهات ذات العلاقة'
                }

            }
        },
        {
            path: '/recruitment',
            name: 'Recruitment',
            component: () => import('@/views/Recruitment.vue'),
            meta: {
                title: {
                    en: 'Recruitment',
                    ar: 'التوظيف'
                }

            }
        },
        {
            path: '/appointment-booking-request',
            name: 'AppointmentBookingRequest',
            component: () => import('@/views/AppointmentBooking/AppointmentBookingRequest.vue'),
            meta: {
                title: {
                    en: 'Appointment Booking',
                    ar: 'حجز موعد'
                }
            }
        },
        {
            path: '/AppointmentBookingRequestDetail/:id',
            name: 'AppointmentBookingRequestDetail',
            component: () => import('@/views/AppointmentBooking/AppointmentBookingRequestDetail.vue'),
            meta: {
                title: {
                    en: 'Appointment Booking Detail',
                    ar: 'تفاصيل حجز موعد'
                }
            }
        },
        //  complaints
        {
            path: '/ComplaintRequests',
            name: 'ComplaintRequest',
            component: () => import('@/views/Complaints/ComplaintRequest.vue'),
            meta: {
                title: {
                    en: 'Complaint Request',
                    ar: 'بلاغ تعدي'
                }

            }

        },
        {
            path: '/ComplaintRequestDetail/:id',
            name: 'ComplaintRequestDetail',
            component: () => import('@/views/Complaints/ComplaintRequestDetail.vue'),
            meta: {
                title: {
                    en: 'Complaint Request',
                    ar: 'بلاغ تعدي'
                }

            }
        },
        // End of  complaints
        {
            path: '/RequestSurvery/:id',
            name: 'RequestSurvery',
            component: () => import('@/views/RequestSurvery/RequestSurvery.vue'),
            meta: {
                title: {
                    en: 'Your Opinion Matters',
                    ar: 'رأيك يهمنا'
                }

            }
        },
        {
            path: '/compensation-inquiry',
            name: 'CompensationInquiry',
            component: () => import('@/views/CompensationInquiry.vue'),
            meta: {
                title: {
                    en: 'Compensation Inquiry',
                    ar: 'الاستعلام عن التعويضات'
                }

            }
        },
        {
            path: '/open-data-policy',
            name: 'OpenDataPolicy',
            component: () => import('@/views/OpenData/OpenDataPolicy.vue'),
            meta: {
                title: {
                    en: 'Open Data Policy',
                    ar: 'سياسةالبيانات المفتوحة'
                }

            }
        },
        {
            path: '/open-data-advantages',
            name: 'OpenDataAdvantages',
            component: () => import('@/views/OpenData/OpenDataAdvantages.vue'),
            meta: {
                title: {
                    en: 'Open Data Advantage',
                    ar: 'الاستفادة من البيانات المفتوحة'
                }

            }
        },
        {
            path: '/Enquiry-Service',
            name: 'EnquiryService',
            component: () => import('@/views/EnquiryService.vue'),
            meta: {
                title: {
                    en: 'Enquiry Service',
                    ar: 'الاستعلام عن المعاملات'
                }

            }
        },
        {
            path: '/en',
            name: 'English',
            component: () => import('@/views/English.vue'),
        },
        {
            path: '/ar',
            name: 'Arabic',
            component: () => import('@/views/Arabic.vue'),
        },
        //default route, new routes must come before this:
        { path: "*", component: () => import('@/views/PageNotFound.vue') },
        {
            path: '/Transferred',
            name: 'Transferred',
            component: () => import('@/views/Transferred/Transferred.vue'),
            meta: {
                title: {
                    en: 'Transferred Platform',
                    ar: 'منصة منقولات'
                }


            }
        },

    ],
    mode: 'history',

})
export default router;

router.beforeEach((to, from, next) => {
    if (localStorage.lang == "ar") {
        document.title = to.meta?.title ? 'الهيئة العامة لعقارات الدولة - ' + to.meta?.title.ar : 'الهيئة العامة لعقارات الدولة'
    }
    else {
        document.title = to.meta?.title ? 'State Properties General Authority - ' + to.meta?.title.en : 'State Properties General Authority'
    }
    // document.title = localStorage.lang == "ar" ? to.meta?.title ? 'الهيئة العامة لعقارات الدولة - ' + to.meta?.title.ar : 'الهيئة العامة لعقارات الدولة' : 'State Properties General Authority -' + to.meta?.title.en;
    next();
});
