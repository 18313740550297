import Vue from 'vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import i18n from './i18n'
import vuetify from '@/assets/plugins/vuetify'
import VeeValidate from "vee-validate";
import Snotify, { SnotifyPosition } from 'vue-snotify'
import "vue2-datepicker/index.css";
import { ar } from "vue2-datepicker/locale/ar";
import { en } from "vue2-datepicker/locale/en";




import '../public/assets/css/animate.css';
import '../public/assets/css/lity.min.css';
//import '../public/assets/css/bootstrap.min.css';
import '../public/assets/css/swiper-bundle.min.css';
//import './assets/scss/style.scss';

import store from './store/index.js'
import InnerHero from '@/components/shared/InnerHero.vue'
import HashTages from '@/components/shared/HashTages.vue'



import App from './App.vue';


VeeValidate.Validator.extend('mobileReg', {
    getMessage: field => `The Mobile must contain at Only Number`,
    validate: value => {
        var strongRegex = new RegExp("^[0-9]{0,10}$");
        return strongRegex.test(value);
    }
});
VeeValidate.Validator.extend('mobile10Digits', {
    getMessage: field => `The Mobile number should be 10 digits`,
    validate: value => {
        var strongRegex = new RegExp("^[0-9]{10}$");
        return strongRegex.test(value);
    }
});
VeeValidate.Validator.extend('OnlyCharctersandSpaces', {
    getMessage: field => `Only characters and spaces are allowed`,
    validate: value => {
        var strongRegex = new RegExp("^[a-zA-Z\u0600-\u06FF ]+$");
        return strongRegex.test(value);
    }
});
VeeValidate.Validator.extend('OnlyCharctersNumbersandSpaces', {
    getMessage: field => `Only characters, numbers and spaces are allowed`,
    validate: value => {
        var strongRegex = new RegExp("^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF-a-zA-Z-0-9- ]+$");
        return strongRegex.test(value);
    }
});
const options = {
    toast: {
        position: SnotifyPosition.rightTop,
        closeOnClick: true
    }
}
Vue.use(Snotify, options);
Vue.config.productionTip = true;
Vue.use(VeeValidate);
Vue.component('InnerHero', InnerHero);
Vue.component('HashTages', HashTages);
Vue.use(
    VueAxios,
    axios,
);



new Vue({
    store,
    router,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount('#app');