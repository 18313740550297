<template>
    <div id="app">
        <MasterLayout>
            <vue-snotify></vue-snotify>

            <router-view />
        </MasterLayout>
    </div>
</template>

<script>
    import MasterLayout from '@/layouts/MasterLayout.vue';

    export default {
        name: 'app',
        components: {
            MasterLayout
        }
    };
</script>

<style lang="scss">
    @import "~vue-snotify/styles/material.css";
    @import "@/assets/scss/style.scss";
    body {
        color: #3B3B3C;
    }

    .main-title {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .vpd-dir-ltr .vpd-actions, .vpd-dir-ltr .vpd-content {
        font-family: inherit !important;
    }

    .modal {
        z-index: 99991;
    }
    .modal-backdrop {
        z-index: 9999;
    }

    .gradient-btn {
        padding: 1rem 1.5rem 1.2rem;
        border-radius: 50px;
        background-image: -webkit-gradient(linear, left top, right top, from(#C1A25D), color-stop(#637861), to(#2E6064));
        background-image: linear-gradient(to right, #C1A25D, #637861, #2E6064);
        display: inline-flex;
        gap: .5rem;
        color: #fff !important;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        -webkit-transition: all ease-in-out .5s;
        transition: all ease-in-out .5s;
        line-height: 1;
        text-align: center;
        min-width: 150px;
        justify-content: center;
    }
    .snotify {
        z-index: 99999999 !important;
    }

    html[dir="rtl"] .mx-icon-calendar, .mx-icon-clear {
        right: unset;
        left: 12px;
    }



    html[dir="ltr"] .mx-icon-calendar, .mx-icon-clear {
        left: unset;
        right: 12px;
    }

    .ndf-header .ndf-top-header .lang-link {
        font-family: sans-serif !important;
        font-weight: 500 !important;
    }

    .container {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .loader {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        display: inline-block;
        border-top: 3px solid #00a99c;
        border-right: 3px solid transparent;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

        .loader.white-style {
            border-top-color: #FFF;
        }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .main--loader {
        padding: 3rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .row > * {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
    }

    .mx-datepicker {
        width: unset !important;
    }
    .search-form-box--input-wrapper.auth-form-group .mx-input-wrapper .auth-form-control {
        padding: 0.6rem 2rem 0.7rem !important
    }

    .search-form-box--input-wrapper.auth-form-group .auth-form-control.v-select {
        padding: 0 !important;
        max-width: 270px !important;
    }

        .auth-form-group .auth-form-control.v-select input, .v-text-field .v-input__append-inner {
            padding: 0 !important;
        }

        .auth-form-group .auth-form-control.v-select .v-input--selection-controls__input .v-icon {
            top: 3px;
        }

            .auth-form-group .auth-form-control.v-select .v-input--selection-controls__input .v-icon:before {
                content: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='square' class='svg-inline--fa fa-square fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%234e4e4e' d='M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z'%3E%3C/path%3E%3C/svg%3E");
                width: 15px;
            }

            .auth-form-group .auth-form-control.v-select .v-input--selection-controls__input .v-icon.primary--text:before {
                content: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='check-square' class='svg-inline--fa fa-check-square fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%234e4e4e' d='M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm0 400H48V80h352v352zm-35.864-241.724L191.547 361.48c-4.705 4.667-12.303 4.637-16.97-.068l-90.781-91.516c-4.667-4.705-4.637-12.303.069-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l59.792 60.277 141.352-140.216c4.705-4.667 12.303-4.637 16.97.068l22.536 22.718c4.667 4.706 4.637 12.304-.068 16.971z'%3E%3C/path%3E%3C/svg%3E");
            }

        .auth-form-group .auth-form-control.v-select .v-list-item__title {
            padding: 0 .5rem;
            line-height: 1.5;
        }

    .search-form-box {
        align-items: center !important;
    }


    .loading-logo {
        width: 130px;
        height: 130px;
        --logo-color: #FFF;
    }

        .loading-logo.colored-logo {
            --logo-color: #0088a7;
        }

        .loading-logo path {
            fill: #FFF;
            stroke: var(--logo-color);
            stroke-width: 0;
            stroke-dasharray: 200;
            animation: logoAnimation 2.5s ease-in-out alternate infinite;
        }

    @keyframes logoAnimation {
        0% {
            stroke-width: .75px;
            stroke-dashoffset: 200;
            fill: transparent;
            filter: unset;
        }

        60% {
            stroke-width: .75px;
            stroke-dashoffset: 0;
            fill: transparent;
            filter: unset;
        }

        100% {
            stroke-width: 0;
            fill: var(--logo-color);
        }
    }


    .auth-form-group .auth-form-control.v-select.full-width {
        max-width: unset !important;
    }
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
        box-shadow: none !important;
    }

    .auth-form-group .auth-form-control.v-select.full-width .v-select__selections {
        height: 57px !important;
    }

    .full-width .v-text-field.v-text-field--enclosed .v-text-field__details, .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
        background: #fcfcfc;
    }



    .inner-page--breadcrumb {
        display: none !important;
    }


    svg:not(:root) {
        overflow: visible !important;
    }

    .subscribe-input + .form-error-text {
        color: #bf0000;
    }
</style>

